import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../frontend/home';
import BlogDetail from '../frontend/blogs/BlogDetail';
import BlogCategoryPage from '../frontend/blogs/Category';
import BlogPage from '../frontend/blogs';
import ContactPage from '../frontend/contacts';
import FaqPage from '../frontend/faqs';
import Product from '../frontend/product';
import ProductDetail from '../frontend/ProductDetail';
import CategoryPage from '../frontend/category';
import ChildCategory from '../frontend/child-category';
import AboutUs from '../frontend/abouts';
import Project from '../frontend/project';
import ServiceDetail from '../frontend/service';
import ProjectDetail from '../frontend/projectDetail';
import CategoryDetail from '../frontend/CategoryDetail';
import PoolSpaCover from '../frontend/PoolSpaCover';
import PoolPump from '../frontend/PoolPump';

import RoboticPoolCleaner from '../frontend/RoboticPoolCleaner';
import Dehumidifier from '../frontend/dehumidifier';
import StainlessWaterTank from '../frontend/stainlesswatertank';
import StainlessPanelTank from '../frontend/stainlesspaneltank';
import PrivacyPolicy from '../frontend/privacy-policy';
import PageNotFound from '../frontend/page-not-found';
import TermsAndCondition from '../frontend/privacy-policy/TermsAndCondition';
import CompanyPolicies from '../frontend/company-policie';
import PaymentPolicy from '../frontend/privacy-policy/PaymentPolicy';
import DealerPolicy from '../frontend/privacy-policy/DealerPolicy';
import AllCategory from '../frontend/category/AllCategory';
import SolarCollector from '../frontend/SolarCollector';
import SolarThermosyphon from '../frontend/SolarThermosyphon';
import RadiatorPage from '../frontend/radiator';
import GlassLinedTank from '../frontend/GlassTank';
import ComingSoonPage from '../frontend/coming-soon';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route exact path="/" element={<Home />} />
                        {/* Blog Page */}
                        <Route path="/blogs" element={<BlogPage />} />
                        <Route path="/blogs/:blogId" element={<BlogDetail />} />
                        <Route
                              path="/blogs/categories/:categoryId"
                              element={<BlogCategoryPage />}
                        />
                        {/* Contact */}
                        <Route path="/contact-us" element={<ContactPage />} />
                        {/* Faqs */}
                        <Route path="/faqs" element={<FaqPage />} />
                        {/* Product List Page */}
                        <Route
                              path="/products/:categoryId/category"
                              element={<Product />}
                        />
                        {/* Product List Page */}
                        <Route
                              path="/products/:productId"
                              element={<ProductDetail />}
                        />
                        {/* About Us */}
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route
                              path="/category/:categoryId"
                              element={<CategoryPage />}
                        />
                        <Route
                              path="/category/:categoryId/:childCategory"
                              element={<ChildCategory />}
                        />
                        {/* Project */}
                        <Route path="/projects" element={<Project />} />
                        {/* category page  */}
                        <Route path="/category" element={<AllCategory />} />
                        <Route
                              path="/projects/:projectId"
                              element={<ProjectDetail />}
                        />
                        {/* pool and spa cover separate page */}
                        <Route
                              path="/pool-and-spa-cover"
                              element={<PoolSpaCover />}
                        />
                        {/* pool pump separate page */}
                        <Route path="/pool-pump" element={<PoolPump />} />
                        {/* Service */}
                        <Route
                              path="/services/:serviceId"
                              element={<ServiceDetail />}
                        />
                        <Route
                              path="/heat-pump-air-dryer"
                              element={<CategoryDetail />}
                        />
                        {/* robotic pool cleaner  */}
                        <Route
                              path="/robotic-pool-cleaner"
                              element={<RoboticPoolCleaner />}
                        />
                        {/* Dehumidifire  */}
                        <Route
                              path="/dehumidifier"
                              element={<Dehumidifier />}
                        />
                        {/* stainless steel panel tank  */}
                        <Route
                              path="/hot-water-storage-tank"
                              element={<StainlessWaterTank />}
                        />
                        {/* stainless steel panel tank  */}
                        <Route
                              path="/modular-panel-tank"
                              element={<StainlessPanelTank />}
                        />
                        {/* privacy policy page */}
                        <Route
                              path="/payment-policy"
                              element={<PaymentPolicy />}
                        />
                        {/* /solar heating page */}
                        <Route
                              path="/thermal-solar-collector"
                              element={<SolarCollector />}
                        />
                        <Route
                              path="/pressurized-thermosiphon"
                              element={<SolarThermosyphon />}
                        />
                        {/* radiator product page */}
                        <Route
                              path="/radiator-and-fancoil"
                              element={<RadiatorPage />}
                        />
                        {/* /glass-lined-tank */}
                        <Route
                              path="/glass-lined-hot-water-storage-tank"
                              element={<GlassLinedTank />}
                        />
                        <Route
                              path="/dealer-policy"
                              element={<DealerPolicy />}
                        />
                        <Route
                              path="/company-policy"
                              element={<CompanyPolicies />}
                        />
                        <Route
                              path="/terms-and-condition"
                              element={<TermsAndCondition />}
                        />
                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                        />
                        {/* Coming Soon Page */}
                        <Route
                              path="/healthcare"
                              element={<ComingSoonPage />}
                        />
                        <Route path="/drcare" element={<ComingSoonPage />} />
                        <Route
                              path="/hamropaani"
                              element={<ComingSoonPage />}
                        />

                        {/* Page Not Found */}
                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
