import { Col, Container } from 'react-bootstrap';
import { styled } from 'styled-components';

export const FooterContainer = styled.footer`
      background-color: ${(props) => props.theme.primary};
`;

export const FooterBody = styled(Container)`
      padding: 70px 0px 50px;

      @media only screen and (max-width: 568px) {
            padding-left: 10px;
            padding-right: 10px;

            .g-5 {
                  --bs-gutter-y: 0rem;
                  --bs-gutter-x: 0rem;
            }

            .mobile__footer {
                  width: 100%;
                  flex: auto;
            }
      }
`;

export const FooterItem = styled(Col)``;

export const FooterHeading = styled.div`
      margin-bottom: 1rem;

      h3,
      h4 {
            color: ${(props) => props.theme.white};
            font-size: 20px;

            img {
                  width: 150px;
            }
      }
`;

export const FooterContent = styled.div`
      margin-bottom: ${(props) => props.marginBottom && '1.5rem'};
      p {
            color: #a5a5a5;
            text-align: justify;
            font-size: 14px;
            font-family: 'Nunito Sans', sans-serif;
      }

      a {
            color: #a5a5a5;
            transition: all 0.3s ease-in;

            &:hover {
                  color: ${(props) => props.theme.secondary};
            }
      }

      ul {
            padding: 0;

            li {
                  list-style-type: none;
                  margin-bottom: 10px;

                  a {
                        color: #a5a5a5;
                        font-family: 'Rajdhani', sans-serif;
                        transition: all 0.3s ease-in;

                        &:hover {
                              color: ${(props) => props.theme.secondary};
                              margin-left: 10px;
                        }
                  }
            }
      }
`;

export const FooterContact = styled.div`
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;

      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
      }

      span {
            &:first-child {
                  color: ${(props) => props.color || props.theme.white};
            }

            color: #a5a5a5;
      }
`;

export const FooterCredential = styled.div`
      font-size: 14px;
      font-family: 'Rajdhani', sans-serif;

      @media only screen and (max-width: 568px) {
            padding-left: 10px;
            padding-right: 10px;
      }
`;

export const FooterCredentialContainer = styled(Container)`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      border-top: 1px solid #4c4c4c;
      color: ${(props) => props.theme.white};

      a,
      span {
            color: ${(props) => props.theme.secondary};
      }

      @media only screen and (max-width: 568px) {
            flex-direction: column;
            justify-content: left;
            align-items: start;
      }
`;

export const FooterLogoContainer = styled.div``;
export const FooterParent = styled.div`
      position: relative;
      border-left: 1px solid #fff;
      padding-left: 30px;
      &:after {
            position: absolute;
      }
      img {
            width: 80px;
      }
`;
