import React from 'react';
import { Row } from 'react-bootstrap';
import {
      FooterBody,
      FooterContact,
      FooterContainer,
      FooterContent,
      FooterCredential,
      FooterCredentialContainer,
      FooterHeading,
      FooterItem,
      FooterLogoContainer,
      FooterParent
} from '../../../styles/layouts/footer';
import FooterMenuItem from './FooterMenuItem';
import { LocationIcon, PhoneIcon, MailIcon } from '../../../icons';
import SocialMedia from '../../../components/common/SocialMedia';
import { Link } from 'react-router-dom';

const Footer = () => {
      const currentYear = new Date().getFullYear();
      return (
            <>
                  <FooterContainer>
                        <FooterBody>
                              <Row className="g-5">
                                    <FooterItem lg={4}>
                                          <FooterHeading>
                                                <FooterLogoContainer className="d-flex gap-4 align-items-center mb-4">
                                                      <Link to={'/'}>
                                                            <h3>
                                                                  <img
                                                                        src="/images/png/Innmotek White@2x 1.png"
                                                                        alt="Innmotek white logo"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </h3>
                                                      </Link>
                                                      {/* <FooterParent>
                                                            <img
                                                                  src="/images/png/kswhite.png"
                                                                  alt="Innmotek white logo"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </FooterParent> */}
                                                </FooterLogoContainer>
                                          </FooterHeading>
                                          <FooterContent marginBottom={true}>
                                                <p>
                                                      Innmotek offers
                                                      top-quality Hot Water and
                                                      Pool Heat Pumps, along
                                                      with accessories, serving
                                                      residential, commercial,
                                                      and industrial sectors.
                                                </p>
                                          </FooterContent>
                                          <SocialMedia />
                                    </FooterItem>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Quick Links</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            link="/faqs"
                                                            title="FAQs"
                                                      />
                                                      <FooterMenuItem
                                                            link="/projects"
                                                            title="Projects"
                                                      />
                                                      <FooterMenuItem
                                                            link="/contact-us"
                                                            title="Contact Us"
                                                      />
                                                      <FooterMenuItem
                                                            link="/blogs"
                                                            title="Blogs and News"
                                                      />
                                                      <FooterMenuItem
                                                            link="/company-policy"
                                                            title="Company Policy"
                                                      />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Our Products</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            title="Heat Pump"
                                                            link="/category/heat-pump"
                                                      />
                                                      {/* <FooterMenuItem title="Heaters" /> */}
                                                      <FooterMenuItem
                                                            title="Pool & Spa"
                                                            link="/category/pool-and-spa"
                                                      />
                                                      <FooterMenuItem
                                                            title="Radiator & Fancoil"
                                                            link="/radiator-and-fancoil"
                                                      />
                                                      <FooterMenuItem
                                                            title="Solar Water Heaters"
                                                            link="/category/solar-water-heaters"
                                                      />
                                                      <FooterMenuItem
                                                            title="Hot Storage Water Tank"
                                                            link="/category/hot-water-storage-tanks"
                                                      />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem className="mobile__footer">
                                          <FooterHeading>
                                                <h4>Contact Us</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <FooterContact color="#a5a5a5">
                                                      <span>India</span>
                                                      <span>|</span>
                                                      <span> UAE</span>
                                                      <span>|</span>
                                                      <span>Nepal</span>
                                                </FooterContact>
                                                <FooterContact>
                                                      <PhoneIcon />
                                                      <Link to="tel:918081741031">
                                                            +91 808 174 1031
                                                      </Link>
                                                </FooterContact>
                                                <FooterContact>
                                                      <MailIcon />
                                                      <Link to="mailto:info@innmotek.com">
                                                            info@innmotek.com
                                                      </Link>
                                                </FooterContact>
                                                <FooterContact>
                                                      <LocationIcon />
                                                      <span>
                                                            GIDA, Uttar Pradesh,
                                                            273209
                                                      </span>
                                                </FooterContact>
                                          </FooterContent>
                                    </FooterItem>
                              </Row>
                        </FooterBody>
                        <FooterCredential>
                              <FooterCredentialContainer>
                                    <div class="footer__copyright">
                                          Copyright © {currentYear} by{' '}
                                          <span>Innmotek LLP</span>
                                    </div>
                                    <div class="footer__credit">
                                          Powered By:{' '}
                                          <Link
                                                to="https://infinityinfosys.com"
                                                target="_blank"
                                          >
                                                Infinity Infosys
                                          </Link>
                                    </div>
                              </FooterCredentialContainer>
                        </FooterCredential>
                  </FooterContainer>
            </>
      );
};

export default Footer;
