import React, { useEffect, useRef } from 'react';
import Mmenu from 'mmenu-js';
import 'mmenu-js/dist/mmenu.css';
import { Link } from 'react-router-dom';

const MobileMenuItem = ({ setShow, loading, categories, services }) => {
      const menuRef = useRef(null);

      useEffect(() => {
            if (!menuRef.current) {
                  menuRef.current = new Mmenu('#menu', {
                        offCanvas: {
                              use: false
                        },
                        counters: {
                              add: true
                        },
                        setSelected: {
                              hover: true
                        },
                        navbars: [
                              {
                                    position: 'top',
                                    content: ['searchfield']
                              }
                        ]
                  });
            }
      }, []);
      return (
            <>
                  <nav id="menu">
                        <ul>
                              <li>
                                    <Link onClick={() => setShow()} to="/">
                                          Home
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/about-us"
                                    >
                                          About
                                    </Link>
                              </li>
                              <li>
                                    <span>Products</span>
                                    {!loading ? (
                                          categories.length > 0 ? (
                                                <ul>
                                                      {categories?.map(
                                                            (
                                                                  category,
                                                                  index
                                                            ) => (
                                                                  <li
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={
                                                                                    category.slug ===
                                                                                    'radiator-and-fancoil'
                                                                                          ? '/radiator-and-fancoil'
                                                                                          : `/category/${category.slug}`
                                                                              }
                                                                              onClick={() =>
                                                                                    setShow()
                                                                              }
                                                                              className="main__category"
                                                                        >
                                                                              {
                                                                                    category.title
                                                                              }
                                                                        </Link>
                                                                        {category
                                                                              ?.subCategory
                                                                              ?.length >
                                                                              0 && (
                                                                              <ul>
                                                                                    {category?.subCategory?.map(
                                                                                          (
                                                                                                item,
                                                                                                key
                                                                                          ) => (
                                                                                                <li
                                                                                                      key={
                                                                                                            key
                                                                                                      }
                                                                                                >
                                                                                                      {item.slug ===
                                                                                                      'pool-pumps' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/pool-pumps"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'thermal-solar-collector' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/thermal-solar-collector"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'pressurized-thermosiphon' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/pressurized-thermosiphon"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'pool-and-spa-cover' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/pool-and-spa-cover"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'hot-water-storage-tanks' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/hot-water-storage-tanks"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'hot-water-storage-tank' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/hot-water-storage-tank"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'modular-panel-tank' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/modular-panel-tank"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'robotic-pool-cleaner' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/robotic-pool-cleaner"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : item.slug ===
                                                                                                        'dehumidifier' ? (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to="/dehumidifier"
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      ) : (
                                                                                                            <Link
                                                                                                                  onClick={() =>
                                                                                                                        setShow()
                                                                                                                  }
                                                                                                                  to={`${
                                                                                                                        item.categories ===
                                                                                                                        0
                                                                                                                              ? '/products/'
                                                                                                                              : '/category/'
                                                                                                                  }${
                                                                                                                        category.slug
                                                                                                                  }${
                                                                                                                        item.categories ===
                                                                                                                        0
                                                                                                                              ? '/category'
                                                                                                                              : '/' +
                                                                                                                                item.slug
                                                                                                                  }`}
                                                                                                            >
                                                                                                                  <i className="bx bx-chevron-right"></i>
                                                                                                                  {
                                                                                                                        item.title
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      )}
                                                                                                </li>
                                                                                          )
                                                                                    )}
                                                                              </ul>
                                                                        )}
                                                                  </li>
                                                            )
                                                      )}
                                                </ul>
                                          ) : null
                                    ) : null}
                              </li>
                              <li>
                                    <span>Services</span>
                                    <ul>
                                          {!loading
                                                ? services?.length > 0 &&
                                                  services?.map(
                                                        (item, index) => (
                                                              <li key={index}>
                                                                    <Link
                                                                          onClick={() =>
                                                                                setShow()
                                                                          }
                                                                          to={`/services/${item.slug}`}
                                                                    >
                                                                          {
                                                                                item.title
                                                                          }
                                                                    </Link>
                                                              </li>
                                                        )
                                                  )
                                                : null}
                                    </ul>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/projects"
                                    >
                                          Projects
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/contact-us"
                                    >
                                          Contact Us
                                    </Link>
                              </li>
                        </ul>
                  </nav>
            </>
      );
};

export default MobileMenuItem;
