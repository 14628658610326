import React from 'react';
import PageNotFound from '../page-not-found';

const ComingSoonPage = () => {
      return (
            <>
                  <PageNotFound
                        fontSize="5 vw"
                        title="Coming Soon"
                        subTitle="Our Projects are coming soon."
                        desc="We are working on it."
                  />
            </>
      );
};

export default ComingSoonPage;
